import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import cn from "classnames";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import {clearClientForUpdate, editClient,} from "../../actions/client";
import {getAllGasStations} from "../../actions/gasStation";
import {getAllParkingZones} from "../../actions/parkingZone";
import AddressesComponent from "../../common/address/AddressesComponent";
import XIcon from "../../common/assets/x-icon.svg";
import Button from "../../common/button/button";
import InputController from "../../common/input/inputController";
import {ROLES} from "../../consts";
import keycloak from "../../keycloak";
import ModalLoadingIndicator from "../../reusable/modalLoadingIndicator";
import {newModalStyle} from "../../reusable/styles";
import Style from "./editClientModal.module.css";

const EditClientModal = (props) => {
    const auth = useAuth();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { handleSubmit, register, watch, control} = useForm();
    const { isLoading } = useSelector((state) => state.loader);

    const isSysAdmin = keycloak.hasRealmRole(ROLES.SYSADMIN, auth);

    const { clientForUpdate } = useSelector((state) => state.client);

    const { parkingZones } = useSelector((state) => state.parkingZone);
    const { gasStations } = useSelector((state) => state.gasStation);
    const [pzIds, setPzIds] = useState([]);
    const [gsIds, setGsIds] = useState([]);
    const [pzComboBoxNames, setPzComboBoxNames] = useState([]);
    const [gsComboBoxNames, setGsComboBoxNames] = useState([]);
    const [pzCounter, setPzCounter] = useState(0);
    const [gsCounter, setGsCounter] = useState(0);
    const createParkingZoneDto = (id) => ({ id });
    const createGasStationZoneDto = (id) => ({ id });

    useEffect(() => {
        setPowerOfAttorneyName(
          clientForUpdate.powerOfAttorneyName ? clientForUpdate.powerOfAttorneyName : ""
        );
      }, [clientForUpdate]);

    useEffect(() => {
        setDocumentationName(
          clientForUpdate.documentationName ? clientForUpdate.documentationName : ""
        );
      }, [clientForUpdate]);

    const [powerOfAttorneyName, setPowerOfAttorneyName] = useState("");
    const [powerOfAttorney, setPowerOfAttorney] = useState(null);
    const [documentation, setDocumentation] = useState(null);
    const [documentationName, setDocumentationName] = useState("");
    const [address, setAddress] = useState(clientForUpdate?.address);
    const nameWatch = watch("name", clientForUpdate?.name);
    const representativeNameWatch = watch("representativeName", clientForUpdate?.representativeName);
    const emailWatch = watch("email", clientForUpdate?.email);

    useEffect(() => {
        if (!clientForUpdate?.parkingZones) {
          setPzIds([]);
          setPzComboBoxNames([]);
          setPzCounter(0);
        } else {
          setPzIds(clientForUpdate?.parkingZones.map(pz => pz.id));
          const array = clientForUpdate?.parkingZones.map((pz, index) => index + 1);
          setPzComboBoxNames(array);
          setPzCounter(clientForUpdate?.parkingZones.length);
        }
        if (!clientForUpdate?.gasStationZones) {
          setGsIds([]);
          setGsComboBoxNames([]);
          setGsCounter(0);
        } else {
          setGsIds(clientForUpdate?.gasStationZones.map(pz => pz.id));
          const array = clientForUpdate?.gasStationZones.map((gs, index) => index + 1);
          setGsComboBoxNames(array);
          setGsCounter(clientForUpdate?.gasStationZones.length);
        }
    }, [clientForUpdate]);


    const documentUpload = (e, files, setFiles, filesNames, setFilesNames, isSingleFile) => {
        if (e.target.files.length !== 0) {
         let fileList = files === null ? [] : files;
                 let names = filesNames;
                 for (let i = 0; i < e.target.files.length; i++) {
                   if (isSingleFile && i === 0) {
                     fileList = [];
                     fileList.push(e.target.files[i])
                     names = e.target.files[i].name;
                   } else {
                     fileList.push(e.target.files[i]);
                     names = names === "" ? e.target.files[i].name : names.concat("|", e.target.files[i].name);
                   }
                 }
                 setFiles(fileList);
                 setFilesNames(names);
               }
    };


    const removeDocument = (
        files,
        setFiles,
        filesNames,
        setFilesNames,
        elementForRemoveName
      ) => {
        files !== null &&
          setFiles([...files?.filter((f) => f.name !== elementForRemoveName)]);
        const namesArray = filesNames
          ?.split("|")
          .filter((fn) => fn !== elementForRemoveName);
        setFilesNames(namesArray?.join("|"));
      };

    const showDocumentsNames = (files, setFiles, filesNames, setFilesNames) => {
        return filesNames?.split("|")?.map((n, index) => (
          <div key={index} className={Style.documentRow}>
            <div className={Style.uploadedFileName}>{n}</div>
            <div className={Style.documentLine} />
            <div className={Style.removeDocument}>
              <img
                alt=""
                src={XIcon}
                height="25px"
                width="25px"
                onClick={() => {
                  removeDocument(files, setFiles, filesNames, setFilesNames, n);
                }}
              />
            </div>
          </div>
        ));
      };

    useEffect(() => {
        if (isSysAdmin) {
                  dispatch(getAllParkingZones());
                  dispatch(getAllGasStations());
        }
        return () => {
          dispatch(clearClientForUpdate());
        };
    }, [dispatch, isSysAdmin]);

  const renderParkingZones = () => {
    const getListOfParkingZones = (parkingZones, ordinalNumber) => {
      if (ordinalNumber === 1) return parkingZones;
      return parkingZones.filter(
        (p) => !pzIds.slice(0, ordinalNumber - 1).includes(p.id)
      );
    };

    return (
      <div>
        {pzIds.map((pz, index) => {
          const ordinalNumber = index + 1;

          return (
            <div key={index} className={Style.combo}>
              <div className={Style.combo__select}>
                <Controller
                    name={`parkingZone_${pzComboBoxNames[index]}`}
                    control={control}
                    render={() => (
                        <Autocomplete
                            className={Style.textStyleSelectEditModal}
                            id={`parkingZone_${pzComboBoxNames[index]}`}
                            onChange={(e,value)  => {
                              if(value) {
                                pzIds[index] = value.id;
                                setPzIds([...pzIds]);
                              }
                            }}
                            disableClearable
                            value = {parkingZones.find(x=>x.id===pz) || null}
                            renderInput={(params) => <TextField {...params} placeholder={t("Clients.Choose")}/>}
                            options={getListOfParkingZones(parkingZones, ordinalNumber)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(x) => `${x.parkingZoneNumber} ${x.parkingZoneName}`}
                        />
                    )}
                />
              </div>
              <div className={Style.combo__removeBtn}>
                <img
                  alt=""
                  src={XIcon}
                  height="25px"
                  width="25px"
                  onClick={(e) => {
                    setPzIds([
                      ...pzIds.filter((m, i) => i !== index),
                    ]);
                    pzComboBoxNames.splice(index, 1);
                    setPzComboBoxNames([...pzComboBoxNames]);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderGasStations = () => {
    const getListOfGasStations = (gasStations, ordinalNumber) => {
      if (ordinalNumber === 1) return gasStations;
      return gasStations.filter(
        (g) => !gsIds.slice(0, ordinalNumber - 1).includes(g.id)
      );
    };

    return (
      <div>
        {gsIds.map((gs, index) => {
          const ordinalNumber = index + 1;

          return (
            <div key={index} className={Style.combo}>
              <div className={Style.combo__select}>
                <Controller
                    name={`gasStation_${gsComboBoxNames[index]}`}
                    control={control}
                    render={() => (
                        <Autocomplete
                            className={Style.textStyleSelectEditModal}
                            id={`gasStation_${gsComboBoxNames[index]}`}
                            onChange={(e,value)  => {
                              if(value) {
                                gsIds[index] = value.id;
                                setGsIds([...gsIds]);
                              }
                            }}
                            disableClearable
                            value = {gasStations.find(x=>x.id===gs) || null}
                            renderInput={(params) => <TextField {...params} placeholder={t("Clients.Choose")}/>}
                            options={getListOfGasStations(gasStations, ordinalNumber)}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(x) => `${x.number} ${x.name}`}
                        />
                    )}
                />
              </div>
              <div className={Style.combo__removeBtn}>
                <img
                  alt=""
                  src={XIcon}
                  height="25px"
                  width="25px"
                  onClick={(e) => {
                    setGsIds([
                      ...gsIds.filter((m, i) => i !== index),
                    ]);
                    gsComboBoxNames.splice(index, 1);
                    setGsComboBoxNames([...gsComboBoxNames]);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

    const isSubmitDisabled =
        !address|| !nameWatch || !representativeNameWatch || !emailWatch;


    const updateClient = async (data) => {
        const formData = new FormData();

        formData.append("powerOfAttorney", powerOfAttorney && powerOfAttorney[0]);
        formData.append("powerOfAttorneyName", powerOfAttorneyName);
        for (let i = 0; i < documentation?.length; i++) {
            formData.append("documentation", documentation[i]);
        }
        formData.append("documentationName", documentationName);
        const parkingZones = pzIds.map(createParkingZoneDto);
        const gasStationZones = gsIds.map(createGasStationZoneDto);

        const {
            street,
            zipCode,
            city,
            country,
            ...modifyClient
        } = data;

        const updatedClient = {
            ...modifyClient,
            address,
            parkingZones,
            gasStationZones
        };

        formData.append(
            "client",
            new Blob([JSON.stringify(updatedClient)], {
                type: "application/json",
            })
        );

        await dispatch(
            editClient(clientForUpdate.id, formData, props.onClose)
        );
    };

  return (
    <Modal
          open={props.open}
          closeOnEsc
          onClose={props.onClose}
          center
          styles={newModalStyle({ width: "70%", maxWidth: "900px" })}
    >
      <div className="modal__headerLine" />
      <div className={cn("modal__title", Style.modal__title)}>
        {t("Clients.Client")}
      </div>
      {isLoading && <ModalLoadingIndicator />}
      <form onSubmit={handleSubmit((data) => updateClient(data))}>
          <div className={Style.formContainer}>

              {/* Basic Info */}
              <div className={Style.section}>
                  <div className={Style.section__title}>
                      {t("Clients.BasicInfo")}
                  </div>

                  <div className={Style.rowContainer}>
                      <InputController
                          containerStyles={
                              cn(Style.container, Style["container--margineRightSmall"])
                          }
                          label={t("Clients.Columns.Name")}
                          name="name"
                          control={control}
                          controllerClassName={Style.textStyleInputEditModal}
                          type={"text"}
                          required={true}
                          defaultValue={clientForUpdate?.name}
                      />
                      <InputController
                          containerStyles={cn(Style.container)}
                          label={t("Clients.Columns.RepresentativeName")}
                          name="representativeName"
                          control={control}
                          type={"text"}
                          required={true}
                          controllerClassName={Style.textStyleInputEditModal}
                          defaultValue={clientForUpdate?.representativeName}
                      />
                  </div>
                  <div className={Style.rowContainer}>
                      <InputController
                          containerStyles={cn(Style.container, Style["container--margineRightSmall"])}
                          label={t("Clients.Columns.Email")}
                          name="email"
                          control={control}
                          type={"text"}
                          required={true}
                          controllerClassName={Style.textStyleInputEditModal}
                          defaultValue={clientForUpdate?.email}
                      />
                      <InputController
                          containerStyles={cn(Style.container)}
                          label={t("Clients.Columns.PhoneNumber")}
                          name="phoneNumber"
                          control={control}
                          type={"text"}
                          controllerClassName={Style.textStyleInputEditModal}
                          defaultValue={clientForUpdate?.phoneNumber}
                      />
                  </div>

              </div>

              {/* Address Section */}
              <div className={Style.section}>
                  <AddressesComponent
                      onAddressAdded={setAddress}
                      initialAddress={address}
                      register={register}
                      watch={watch}
                  />
              </div>

              {/* Payment Info Section */}
              <div className={Style.section}>
                  <div className={Style.section__title}>
                      {t("ParkingZones.paymentInfo")}
                  </div>
                  <div className={[Style.rowContainer]}>
                      <InputController
                          containerStyles={cn(
                              Style.container,
                              Style["container--margineRight"]
                          )}
                          label={t("Clients.Iban")}
                          name={"iban"}
                          control={control}
                          controllerClassName={Style.textStyleInputEditModal}
                          type={"text"}
                          defaultValue={clientForUpdate?.iban}
                      />
                      <InputController
                          containerStyles={cn(
                              Style.container
                          )}
                          label={t("Clients.Bic")}
                          name={"bic"}
                          control={control}
                          controllerClassName={Style.textStyleInputEditModal}
                          type={"text"}
                          defaultValue={clientForUpdate?.bic}
                      />
                  </div>
                  <div className={[Style.rowContainer]}>
                      <InputController
                          containerStyles={cn(
                              Style.container,
                              Style["container--margineRight"]
                          )}
                          label={t("Clients.Payee")}
                          name={"payee"}
                          control={control}
                          controllerClassName={Style.textStyleInputEditModal}
                          type={"text"}
                          defaultValue={clientForUpdate?.payee}
                      />
                      <InputController
                          containerStyles={cn(
                              Style.container
                          )}
                          label={t("Clients.FinancialInstitution")}
                          name={"financialInstitution"}
                          control={control}
                          controllerClassName={Style.textStyleInputEditModal}
                          type={"text"}
                          defaultValue={clientForUpdate?.financialInstitution}
                      />
                  </div>
                  <div className={[Style.rowContainer]}>
                      <InputController
                          containerStyles={cn(
                              Style.container,
                              Style["container"]
                          )}
                          label={t("Clients.Columns.BankAccountNumber")}
                          name={"bankAccountNumber"}
                          control={control}
                          controllerClassName={Style.textStyleInputEditModal}
                          type={"text"}
                          defaultValue={clientForUpdate?.bankAccountNumber}
                      />
                  </div>
              </div>

              {/* Documents Section */}
              <div className={Style.section}>
                  <div className={Style.section__title}>
                      {t("Clients.Documents")}
                  </div>
                  <div className={Style.container}>
                      <>
                          <label htmlFor="file-upload" className={Style.fileUpload}>
                              {t("Clients.PowerOfAttorney")}
                          </label>
                          {powerOfAttorneyName !== "" && (
                              <label>
                                  {powerOfAttorneyName === "" ? null : (
                                      <div>
                                          {showDocumentsNames(
                                              powerOfAttorney,
                                              setPowerOfAttorney,
                                              powerOfAttorneyName,
                                              setPowerOfAttorneyName
                                          )}
                                      </div>
                                  )}
                              </label>
                          )}

                          <input
                              id="file-upload"
                              type="file"
                              accept="image/jpg, image/png, image/jpeg"
                              className="powerOfAttorney"
                              onChange={(e) => {
                                  documentUpload(
                                      e,
                                      powerOfAttorney,
                                      setPowerOfAttorney,
                                      powerOfAttorneyName,
                                      setPowerOfAttorneyName,
                                      true
                                  );
                              }}
                              multiple={false}
                          />
                          <label htmlFor="documentation" className={Style.fileUpload}>
                              {t("Clients.BranchDocuments")}
                          </label>
                          {documentationName !== "" && (
                              <label>
                                  {documentationName === "" ? null : (
                                      <div>
                                          {showDocumentsNames(
                                              documentation,
                                              setDocumentation,
                                              documentationName,
                                              setDocumentationName
                                          )}
                                      </div>
                                  )}
                              </label>
                          )}
                          <input
                              id="documentation"
                              type="file"
                              accept=".pdf, .docx"
                              className="documentation"
                              multiple
                              onChange={(e) => {
                                  documentUpload(
                                      e,
                                      documentation,
                                      setDocumentation,
                                      documentationName,
                                      setDocumentationName,
                                      false
                                  );
                              }}
                          />
                      </>
                  </div>
              </div>

              <div className={Style.section}>
                  <div
                      className={cn(
                          Style.section__title,
                          Style["section__title--admin"]
                      )}
                  >
                      {t("Clients.ParkingZones")}
                  </div>

                  {renderParkingZones()}

                  <button
                      type="button"
                      className={
                          pzIds?.includes("0")
                              ? cn(
                                  Style.addComboBoxButton,
                                  Style["addComboBoxButton--disabled"]
                              )
                              : cn(
                                  Style.addComboBoxButton,
                                  Style["addComboBoxButton--enabled"]
                              )
                      }
                      onClick={() => {
                          setPzIds([...pzIds, "0"]);
                          setPzComboBoxNames([
                              ...pzComboBoxNames,
                              pzCounter + 1,
                          ]);
                          setPzCounter(pzCounter + 1);
                      }}
                      disabled={pzIds?.includes("0")}
                  >
                      {t("Clients.AddParkingZone")}
                  </button>

                  <div
                      className={cn(
                          Style.section__title,
                          Style["section__title--admin"]
                      )}
                  >
                      {t("Clients.GasStations")}
                  </div>
                  {renderGasStations()}
                  <button
                      type="button"
                      className={
                          gsIds?.includes("0")
                              ? cn(
                                  Style.addComboBoxButton,
                                  Style["addComboBoxButton--disabled"]
                              )
                              : cn(
                                  Style.addComboBoxButton,
                                  Style["addComboBoxButton--enabled"]
                              )
                      }
                      onClick={() => {
                          setGsIds([...gsIds, "0"]);
                          setGsComboBoxNames([
                              ...gsComboBoxNames,
                              gsCounter + 1,
                          ]);
                          setGsCounter(gsCounter + 1);
                      }}
                      disabled={gsIds?.includes("0")}
                  >
                      {t("Clients.AddGasStation")}
                  </button>
                  <div className={Style.buttonContainer}>
                      <Button
                          style={{
                              width: "25%",
                          }}
                          buttonStyle={{
                              width: "100%",
                          }}
                          disabled={isSubmitDisabled}
                          text={t("Clients.SaveClient")}
                      />
                  </div>
              </div>

          </div>
    </form>
</Modal>
)
    ;
};

export default EditClientModal;