import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {MultiSelect} from "react-multi-select-component";
import {useDispatch, useSelector} from "react-redux";
import {
    calculateGsList,
    calculateGsSAPTicketList,
    calculateGsTicketList,
    calculateGsXMLTicketList,
    calculatePzList,
    calculateTicketList,
    getAllGsOptions,
    getAllPzOptions
} from "../../actions/billingInfo";
import Button from "../../common/button/button";
import SelectWithLabel from "../../common/input/selectWithLabel";
import LabelWithHR from "../../common/labels/labelWithHR";
import ErrorModal from "../../common/modal/errorModal";
import LoadingIndicator from "../../reusable/loadingIndicator";
import {useStyles} from "../../reusable/styles";
import Sidebar from "../../sidebar/sidebar";
import Style from "../parkingZone/addParkingZoneModal.module.css";
import InnerStyle from "./billingInfo.module.css"

export default function BillingInfo() {
  const {t} = useTranslation();
  const {handleSubmit, register, watch, control} = useForm();
  const dispatch = useDispatch();

  const classes = useStyles();
  const {isLoading} = useSelector((state) => state.loader);

  const [showErrorModal, setShowErrorModal] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
    const [xmlEndDate, setXMLEndDate] = useState(null);
    const [sapDate, setSapDate] = useState(null);
    const [selectedPz, setSelectedPz] = useState([]);
    const [selectedGs, setSelectedGs] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const { parkingZones } = useSelector((state) => state.billingInfo);
    const { gasStationZones } = useSelector((state) => state.billingInfo);
    const { gasStationZoneCustomers } = useSelector((state) => state.billingInfo);

    const actionsDisabled = !startDate || !endDate || selectedPz.length === 0;
    const actionsDisabledGS = !startDate || !endDate || selectedGs.length === 0;
    const actionsDisabledGSXML = !xmlEndDate || selectedCustomer.length === 0;
    const actionsDisabledGSSAP = !sapDate || selectedCustomer.length === 0;

    const exportTypeWatch = watch("exportType", "SOCAR");


    const populatePzOptions = async () => {
    await dispatch(getAllPzOptions())
  };

const populateGsOptions = async () => {
    await dispatch(getAllGsOptions())
};

  useEffect(() => {
    populatePzOptions();
    populateGsOptions();
  }, []);

  const producePzList = async () => {
    const data = {
      startDate: startDate,
      endDate: endDate,
      pzNumbers: selectedPz.map(x => x.value)
    }
    await dispatch(calculatePzList(data, setShowErrorModal))
  };
  const produceTicketList = async () => {
    const data = {
      startDate: startDate,
      endDate: endDate,
      pzNumbers: selectedPz.map(x => x.value)
    }
    await dispatch(calculateTicketList(data, setShowErrorModal))
  };

    const produceGsList = async () => {
        const data = {
            startDate: startDate,
            endDate: endDate,
            pzNumbers: selectedGs.map(x => x.value)
        }
        await dispatch(calculateGsList(data, setShowErrorModal))
    };
    const produceGsTicketList = async () => {
        const data = {
            startDate: startDate,
            endDate: endDate,
            pzNumbers: selectedGs.map(x => x.value)
        }
        await dispatch(calculateGsTicketList(data, setShowErrorModal))
    };

    const produceGsXMLTicketList = async () => {
        const data = {
            endDate: xmlEndDate,
            customers: selectedCustomer.map(x => x.value)
        }
        await dispatch(calculateGsXMLTicketList(data, setShowErrorModal))
    };

    const produceGsSAPTicketList = async () => {
        const data = {
            endDate: sapDate,
            customers: selectedCustomer.map(x => x.value),
            exportType: exportTypeWatch
        }
        await dispatch(calculateGsSAPTicketList(data, setShowErrorModal))
    };

  return (
    <div className={classes.root}>
      <>
        <Sidebar/>
        <main className={classes.content}>
          <div className={classes.appBarSpacer}/>
          <Container maxWidth={false} className={classes.container}>
            {isLoading && <LoadingIndicator/>}
            <Grid container spacing={3}>
                <Grid item xs={3}/>
                <Grid item xs={6}>
                    <div className="text-center">
                        <div className="row content">
                            <div className="col-sm-12">
                                <div className={classes.paper}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginRight: "30px",
                                            marginLeft: "30px",
                                            marginTop: "20px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div style={{zIndex: 10, flex: 1}}>
                                            <LabelWithHR label={t("BillingInfo.startDate")}/>
                                            <Controller
                                                name="startDate"
                                                control={control}
                                                render={() => (
                                                    <DatePicker
                                                        selected={startDate}
                                                        dateFormat="dd.MM.yyyy"
                                                        popperPlacement="top"
                                                        className={Style.textStyleInputEditModal}
                                                        locale="de"
                                                        popperProps={{ strategy: 'fixed' }}
                                                        onChange={(date) => {
                                                            setStartDate(date)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div style={{zIndex: 2, flex: 1, marginLeft: "40px"}}>
                                            <LabelWithHR label={t("BillingInfo.endDate")}/>
                                            <Controller
                                                name="endDate"
                                                control={control}
                                                render={() => (
                                                    <DatePicker
                                                        selected={endDate}
                                                        dateFormat="dd.MM.yyyy"
                                                        popperPlacement="top"
                                                        className={Style.textStyleInputEditModal}
                                                        locale="de"
                                                        onChange={(date) => {
                                                            setEndDate(date)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={3}/>
                <Grid item xs={2}/>
                <Grid item xs={8}>
                <div className="text-center">
                  <div className="row content">
                    <div className="col-sm-12">
                      <div >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginRight: "30px",
                            marginLeft: "30px",
                            marginTop: "20px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{zIndex: 9, flex: 1,height:"auto", maxWidth: "500px"}}>
                            <LabelWithHR label={t("BillingInfo.parkingZones")}/>
                            <div title={t("BillingInfo.disabledParkingZones")}>
                              <MultiSelect
                                options={parkingZones}
                                value={selectedPz}
                                onChange={setSelectedPz}
                                labelledBy={t("BillingInfo.parkingZones")}
                              />
                            </div>
                          </div>
                          <div className={Style.buttonContainer}>
                            <Button
                              style={{
                                marginLeft: "40px",
                              }}
                              buttonStyle={{
                                width: "100%",
                              }}
                              disabled={actionsDisabled}
                              text={t("BillingInfo.calculatePzList")}
                              onClick={() => producePzList()}
                            />
                          </div>
                          <div className={Style.buttonContainer}>
                            <Button
                              style={{
                                marginLeft: "40px",
                              }}
                              buttonStyle={{
                                width: "100%",
                              }}
                              disabled={actionsDisabled}
                              text={t("BillingInfo.calculateTicketList")}
                              onClick={() => produceTicketList()}
                            />
                          </div>
                          <ErrorModal
                            title={t("BillingInfo.errorTitle")}
                            errorMessage={t("BillingInfo.errorMessage")}
                            open={showErrorModal}
                            onClose={() => setShowErrorModal(false)}
                            width="400px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
                <Grid item xs={2}/>
                <Grid item xs={2}/>
                <Grid item xs={8}>
                <div className="text-center">
                  <div className="row content">
                    <div className="col-sm-12">
                      <div >
                        <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginRight: "30px",
                              marginLeft: "30px",
                              marginTop: "20px",
                              justifyContent: "space-between",
                            }}
                        >
                          <div style={{zIndex: 8, flex: 1, maxWidth: "500px"}}>
                            <LabelWithHR label={t("BillingInfo.gasStations")}/>
                            <div>
                              <MultiSelect
                                  options={gasStationZones?gasStationZones:[]}
                                  value={selectedGs}
                                  onChange={setSelectedGs}
                                  labelledBy={t("BillingInfo.gasStations")}
                              />
                            </div>
                          </div>
                          <div className={Style.buttonContainer}>
                            <Button
                                style={{
                                  marginLeft: "40px",
                                }}
                                buttonStyle={{
                                  width: "100%",
                                }}
                                disabled={actionsDisabledGS}
                                text={t("BillingInfo.calculateGSList")}
                                onClick={() => produceGsList()}
                            />
                          </div>
                          <div className={Style.buttonContainer}>
                            <Button
                                style={{
                                  marginLeft: "40px",
                                }}
                                buttonStyle={{
                                  width: "100%",
                                }}
                                disabled={actionsDisabledGS}
                                text={t("BillingInfo.calculateGSTicketList")}
                                onClick={() => produceGsTicketList()}
                            />
                          </div>
                          <ErrorModal
                              title={t("BillingInfo.errorTitle")}
                              errorMessage={t("BillingInfo.errorMessage")}
                              open={showErrorModal}
                              onClose={() => setShowErrorModal(false)}
                              width="400px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
                <Grid item xs={2}/>
                <Grid item xs={2}/>
                <Grid item xs={8}>
                    <div className="text-center" >
                        <div className="row content">
                            <div className="col-sm-12">
                                <div >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginRight: "30px",
                                            marginLeft: "30px",
                                            marginTop: "20px",
                                            paddingTop:"40px",
                                            justifyContent: "space-between",
                                            borderTop:"1px solid lightgray"
                                        }}
                                    >
                                        <div style={{zIndex: 7, flex: 1, maxWidth: "500px"}}>
                                            <LabelWithHR label={t("BillingInfo.customers")}/>
                                            <div>
                                                <MultiSelect
                                                    options={gasStationZoneCustomers?gasStationZoneCustomers:[]}
                                                    value={selectedCustomer}
                                                    onChange={setSelectedCustomer}
                                                    labelledBy={t("BillingInfo.customers")}
                                                />
                                            </div>
                                        </div>
                                        <div style={{zIndex: 7, flex: 1, marginLeft: "40px"}}>
                                            <LabelWithHR label={t("BillingInfo.endDate")}/>
                                            <Controller
                                                name="xmlEndDate"
                                                control={control}
                                                render={() => (
                                                    <DatePicker
                                                        selected={xmlEndDate}
                                                        dateFormat="dd.MM.yyyy"
                                                        popperPlacement="top"
                                                        className={Style.textStyleInputEditModal}
                                                        locale="de"
                                                        popperPlacement={"bottom"}
                                                        onChange={(date) => {
                                                            setXMLEndDate(date)
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                        <div className={Style.buttonContainer}>
                                            <Button
                                                style={{
                                                    marginLeft: "40px",
                                                }}
                                                buttonStyle={{
                                                    width: "100%",
                                                }}
                                                disabled={actionsDisabledGSXML}
                                                text={t("BillingInfo.calculateGSXMLTicketList")}
                                                onClick={() => produceGsXMLTicketList()}
                                            />
                                        </div>
                                        <ErrorModal
                                            title={t("BillingInfo.errorTitle")}
                                            errorMessage={t("BillingInfo.errorMessage")}
                                            open={showErrorModal}
                                            onClose={() => setShowErrorModal(false)}
                                            width="400px"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={2}/>
                <Grid item xs={2}/>
                <Grid item xs={8}>
                    <div className="text-center" >
                        <div className="row content">
                            <div className="col-sm-12">
                                <div >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginRight: "30px",
                                            marginLeft: "30px",
                                            marginTop: "20px",
                                            paddingBottom:"400px",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div style={{zIndex: 6, flex: 1, maxWidth: "500px", visibility:"hidden"}}>
                                            <LabelWithHR label={t("BillingInfo.customers")}/>
                                            <div>
                                                <MultiSelect
                                                    options={gasStationZoneCustomers?gasStationZoneCustomers:[]}
                                                    value={selectedCustomer}
                                                    onChange={setSelectedCustomer}
                                                    labelledBy={t("BillingInfo.customers")}
                                                />
                                            </div>
                                        </div>
                                        <div style={{zIndex: 6, flex: 1, display:"flex", marginLeft: "20px"}}>
                                            <div className={"col-sm-6"}>
                                                <LabelWithHR label={t("BillingInfo.month")}/>
                                                <Controller
                                                    name="xmlEndDate"
                                                    control={control}
                                                    render={() => (
                                                        <DatePicker
                                                            selected={sapDate}
                                                            dateFormat="MM.yyyy"
                                                            showMonthYearPicker
                                                            className={Style.textStyleInputEditModal}
                                                            popperClassName={InnerStyle.showFullCalendar}
                                                            locale="de"
                                                            popperPlacement={"left"}
                                                            onChange={(date) => {
                                                                setSapDate(date)
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className={"col-sm-6"}>
                                                <SelectWithLabel
                                                        name="exportType"
                                                        label={t("BillingInfo.exportType")}
                                                        style={{ marginBottom: "0px", lineHeight: 1.43}}
                                                        whiteBackground
                                                        registerName="exportType"
                                                        register={register}
                                                        optionValue={"SOCAR"}
                                                        defaultOption={
                                                            "Socar"
                                                        }
                                                        options={["Valora"]?.map(
                                                            (val, index) => {
                                                                return (
                                                                    <option key={index + 1} value={val.toUpperCase()}>
                                                                        {val}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    />
                                            </div>
                                            </div>

                                        <div className={Style.buttonContainer}>
                                            <Button
                                                style={{
                                                    marginLeft: "40px",
                                                }}
                                                buttonStyle={{
                                                    width: "100%",
                                                }}
                                                disabled={actionsDisabledGSSAP}
                                                text={t("BillingInfo.calculateGSSAPTicketList")}
                                                onClick={() => produceGsSAPTicketList()}
                                            />
                                        </div>
                                        <ErrorModal
                                            title={t("BillingInfo.errorTitle")}
                                            errorMessage={t("BillingInfo.errorMessage")}
                                            open={showErrorModal}
                                            onClose={() => setShowErrorModal(false)}
                                            width="400px"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
          </Container>
        </main>
      </>
    </div>
  );
}
